// Import base theme
@import '~@dragonchain-dev/dragon-dashboard/dist/assets/styles/dashboard_theme';

// Import base styles
@import '~@dragonchain-dev/dragon-dashboard/dist/assets/styles/dashboard';

$break-small: 450px;
$max-content-width: 1200px;

.App {

  .header {
    background-image: url(/clouds-blue-fade-to-blue.jpg);

    .contents {
      max-width: $max-content-width;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto;
      padding: 20px;
      min-height: 3vh;
      font-size: calc(10px + 2vmin);

      .logo {
        width: 200px;
        flex: 0;
        color: $white;
        fill: $white;
      }

      .title {
        margin: 0 20px;
        font-size: 18px;
        color: $white;
      }
    }
  }

  .error {
    font-weight: 700;
    color: red;
  }
  .contents {
    max-width: $max-content-width;
    align-items: initial;
    margin: 20px auto;
    padding: 0 20px;
  }

  .inner-panel {
    text-align: center;
  }

  .inner-title {
    background-color: $highlight-color;
    border-radius: 4px;
    color: $white;
    font-weight: 700;
    font-size: 20px;
    padding: 10px 0;
  }

  .inner-value {
    background-color: $white;
    border-radius: 4px;
    color: $text-color;
    font-weight: 700;
    font-size: 20px;
    padding: 10px;
  }

  .table {
    width: 100%;
    margin: 25px 0;

    .cell {
      width: 50%;
      padding: 5px;
    }
  }

  .graph-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -20px -10px;

    .flex-child {
      flex: 1;
      min-width: 400px;
      margin: 20px 10px;

      @media screen and (max-width: $break-small) {
        min-width: 200px;
      }

      .height-100 {
        height: 100%;
      }

      .graph-content {
        overflow: hidden;
        max-height: 200px;
        -webkit-transition: max-height 0.7s;
        -moz-transition: max-height 0.7s;
        -ms-transition: max-height 0.7s;
        -o-transition: max-height 0.7s;
        transition: max-height 0.7s;

        &.expanded {
          max-height: 100%;
        }
      }
    }
  }

}
